import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface Lab {
  id: number;
  name: string;
  eyecatch: string;
  squareEyecatch: string;
}
export interface Labs {
  labs: Lab[];
}


@Module({ dynamic: true, store, name: 'labstore' })
class LabStore extends VuexModule implements Labs {
  public labs = [] as Lab[];

  @Action
  public setLabs(labs: Lab[]) {
    this.SET_LABS(labs);
  }

  @Mutation
  private SET_LABS(labs: Lab[]) {
    this.labs = labs;
  }

}
export const LabModule = getModule(LabStore);
