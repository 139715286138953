





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { LabModule, Lab } from '@/store/labs';
import LabComponent from '@/components/LabComponent.vue';

@Component({
  components: {
    LabComponent
  }
})
export default class Labs extends Vue {
  private get labs(){
    return LabModule.labs;
  }
}
