












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Lab } from '@/store/labs'
@Component
export default class LabComponent extends Vue {
  @Prop() lab!: Lab;

  private get eyecatch(){
    if(this.lab.squareEyecatch)return this.lab.squareEyecatch;
    return this.lab.eyecatch;
  }
}
